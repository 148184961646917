import { defineNuxtPlugin } from '#app/nuxt'
import { LazyFlampIcon, LazyGoogleIcon, LazyIReccomendedIcon, LazyNaPopravkuIcon, LazyOtzovikIcon, LazyProdoctorovIcon, LazySprIcon, LazyTripadvisorIcon, LazyTwoGisIcon, LazyYandexIcon, LazyZoonIcon, LazyIcon, LazyIconCSS, LazyNuxtIcon } from '#components'
const lazyGlobalComponents = [
  ["FlampIcon", LazyFlampIcon],
["GoogleIcon", LazyGoogleIcon],
["IReccomendedIcon", LazyIReccomendedIcon],
["NaPopravkuIcon", LazyNaPopravkuIcon],
["OtzovikIcon", LazyOtzovikIcon],
["ProdoctorovIcon", LazyProdoctorovIcon],
["SprIcon", LazySprIcon],
["TripadvisorIcon", LazyTripadvisorIcon],
["TwoGisIcon", LazyTwoGisIcon],
["YandexIcon", LazyYandexIcon],
["ZoonIcon", LazyZoonIcon],
["Icon", LazyIcon],
["IconCSS", LazyIconCSS],
["NuxtIcon", LazyNuxtIcon],
  
]

export default defineNuxtPlugin({
  name: 'nuxt:global-components',
  setup (nuxtApp) {
    for (const [name, component] of lazyGlobalComponents) {
      nuxtApp.vueApp.component(name, component)
      nuxtApp.vueApp.component('Lazy' + name, component)
    }
  }
})
