import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
  const {
    vueApp,
    $config: {
      public: { SENTRY_DSN, SENTRY_TRACES_SAMPLE_RATE },
    },
  } = nuxtApp
  const router = useRouter()

  if (!SENTRY_DSN) {
    console.warn('Sentry DSN not set, skipping Sentry initialization')
    return
  }

  Sentry.init({
    app: [vueApp],
    dsn: SENTRY_DSN,
    environment: import.meta.dev ? 'development' : 'production',
    enabled: process.env.NODE_ENV !== 'development',

    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,

    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: Number.parseInt(SENTRY_TRACES_SAMPLE_RATE),
    beforeSend(event, hint) {
      // Check if it is an exception, and if so, log it.
      if (event.exception) {
        console.error(
          `[Exception handled by Sentry]: (${hint.originalException})`,
          { event, hint },
        )
      }
      // Continue sending to Sentry
      return event
    },
  })

  vueApp.mixin(
    Sentry.createTracingMixins({
      trackComponents: true,
      timeout: 2000,
      hooks: ['activate', 'mount', 'update'],
    }),
  )
  Sentry.attachErrorHandler(vueApp, {
    logErrors: false,
    attachProps: true,
    trackComponents: true,
    timeout: 2000,
    hooks: ['activate', 'mount', 'update'],
  })

  return {
    provide: {
      sentrySetContext: Sentry.setContext,
      sentrySetUser: Sentry.setUser,
      sentrySetTag: Sentry.setTag,
      sentryAddBreadcrumb: Sentry.addBreadcrumb,
      sentryCaptureException: Sentry.captureException,
    },
  }
})
