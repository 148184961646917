import { onMounted, ref } from 'vue'
import { useStageApi } from '~/composables/useStageApi'

interface ChangeApiResult {
  stageApiInput: { value: string }
  changeApi: () => Promise<void>
  open: { value: boolean }
  loading: { value: boolean }
  enableApiSwitcher: { value: boolean }
}

export function useChangeApi(): ChangeApiResult {
  const {
    $config: {
      public: { nuxtApiSwitcher },
    },
  } = useNuxtApp()
  const stageApi = useStageApi()
  const stageApiInput = ref<string>('')
  const open = ref<boolean>(false)
  const loading = ref<boolean>(false)
  const enableApiSwitcher = ref<boolean>(nuxtApiSwitcher === 'true')

  const changeApi = async (): Promise<void> => {
    if (stageApiInput.value === 'dev' || stageApiInput.value === 'app')
      stageApi.value = `https://${stageApiInput.value}.rangai.ru/api`
    else stageApi.value = `https://${stageApiInput.value}.dev.rangai.ru/api`
    loading.value = true
    await reloadNuxtApp({ ttl: 1000, force: true })
  }

  const keyDownHandler = (e: KeyboardEvent): void => {
    if (enableApiSwitcher.value === false)
      return
    if (e.ctrlKey && e.altKey && e.code === 'ArrowLeft')
      open.value = true
  }

  onMounted(() => {
    window.addEventListener('keydown', keyDownHandler)
  })

  return { stageApiInput, changeApi, open, loading, enableApiSwitcher }
}
