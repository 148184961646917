import type {
  ICreateAccountInput,
  ICreateAccountResponse,
  ILoginInput,
  ILoginResponse,
} from 'types'
import HttpFactory from '../factory'

class AuthModule extends HttpFactory {
  async login(credentials: ILoginInput): Promise<ILoginResponse> {
    return await this.call<ILoginResponse>('POST', '/login', credentials)
  }

  async resetPassword(credentials: ILoginInput): Promise<ILoginResponse> {
    return await this.call<ILoginResponse>(
      'POST',
      '/forgot-password',
      credentials,
    )
  }

  async logout(): Promise<ILoginResponse> {
    return await this.post<ILoginResponse>('/logout')
  }

  async me(): Promise<ILoginResponse> {
    return await this.call<ILoginResponse>('GET', '/user')
  }

  async create(account: ICreateAccountInput): Promise<ICreateAccountResponse> {
    return await this.call<ICreateAccountResponse>(
      'POST',
      '/register',
      account,
    )
  }
}

export default AuthModule
