import type { RouteParamValue } from 'vue-router'
import HttpFactory from '../factory'
import type { ReviewsResponse } from '~/types/reviews'

class BranchModule extends HttpFactory {
  async addNewBranch(data: object): Promise<any> {
    return await this.call<any>('POST', '/branches', data)
  }

  async addRequest(data: object): Promise<any> {
    return await this.call<any>('POST', '/requests', data)
  }

  async getStaff(data: object): Promise<any> {
    return await this.call<any>('POST', '/staff', data)
  }

  async getBranch(id: string | number): Promise<any> {
    return await this.call<any>('GET', `/branches/${id}`)
  }

  async getLandingConfig(
    branchId: number,
    uuid: string,
  ): Promise<any> {
    return await this.call<any>(
      'GET',
      `/branches/${branchId}/landings/${uuid}`,
    )
  }

  async createLanding(branchId: number, data: object): Promise<any> {
    return await this.call<any>(
      'POST',
      `/branches/${branchId}/landings`,
      data,
    )
  }

  async deleteLanding(
    branchId: string | string[],
    landingId: string,
  ): Promise<any> {
    return await this.call<any>(
      'DELETE',
      `/branches/${branchId}/landings/${landingId}`,
    )
  }

  async updateLandingConfig(
    branchId: number,
    landingId: string,
    data: object,
  ): Promise<any> {
    return await this.call<any>(
      'PUT',
      `/branches/${branchId}/landings/${landingId}`,
      data,
    )
  }

  async updateBranchEmployee(
    branchId: number,
    employeeId: number,
    data: object,
  ): Promise<any> {
    return await this.call<any>(
      'PUT',
      `/branches/${branchId}/staffs/${employeeId}`,
      data,
    )
  }

  async createNewEmployee(
    branchId: number,
    data: object,
  ): Promise<any> {
    return await this.call<any>(
      'POST',
      `/branches/${branchId}/staffs`,
      data,
    )
  }

  async changeLandingActiveStatus(
    id: string | string[],
    uuid: string,
  ): Promise<any> {
    return await this.post<any>(
      `/branches/${id}/landings/toggle-active/${uuid}`,
    )
  }

  async changeEmployeeActivity(
    id: string | string[],
    employeeId: number,
  ): Promise<any> {
    return await this.call<any>(
      'PATCH',
      `/branches/${id}/staffs/${employeeId}/toggle-activity`,
    )
  }

  async getBranchLastReviews(id: string | string[]): Promise<any> {
    return await this.get<any>(
      `/branches/${id}/analytics/latest-reviews-in-period`,
    )
  }

  async getBranchLandings(id: string | string[]): Promise<any> {
    return await this.get<any>(`/branches/${id}/landings`)
  }

  async generateBranchApi(id: string | string[]): Promise<any> {
    return await this.post<any>(
        `/branches/${id}/generate-api-key`,
    )
  }

  async getBranchSettings(id: string | string[]): Promise<any> {
    return await this.get<any>(
        `/branches/${id}/settings/main`,
    )
  }

  async updateBranchSettings(id: string | string[], data: object): Promise<any> {
    return await this.call<any>(
      'POST',
        `/branches/${id}/settings/main`,
        data,
    )
  }

  async getRatingTypes(): Promise<any> {
    return await this.get<any>(
      '/rating-types',
    )
  }

  async getBranchCities(): Promise<any> {
    return await this.get<any>(
      '/cities',
    )
  }

  async getTimeZones(): Promise<any> {
    return await this.get<any>(
      '/time-zones',
    )
  }

  async generateApi(): Promise<any> {
    return await this.post<any>('/3.0/user/generate-api-key')
  }

  async getLastReviews(id: string): Promise<any> {
    return await this.get<any>(`/branches/${id}/latest-reviews`)
  }

  async getEmployeeNps(id: string, type: string): Promise<any> {
    return await this.get<any>(
      `/branches/${id}/analytics/nps/${type}/staff`,
    )
  }

  async getBranchNps(
    id: string | string[],
    type: string,
    params: object,
  ): Promise<any> {
    return await this.get<any>(
      `/branches/${id}/analytics/nps/${type}/filial`,
      params,
    )
  }

  async getStaffNps(
    id: string,
    type: string,
    params: object,
  ): Promise<any> {
    return await this.get<any>(
      `/branches/${id}/analytics/nps/${type}/staff`,
      params,
    )
  }

  async getChartData(id: string | string[], params: object): Promise<any> {
    return await this.get<any>(
      `branches/${id}/analytics/ratings-in-period`,
      params,
    )
  }

  async getRequests(id: string | string[]): Promise<any> {
    return await this.get<any>(`branches/${id}/request-for-doctor`)
  }

  async getBranchDynamics(
    id: string | string[],
    params: object,
  ): Promise<any> {
    return await this.get<any>(
      `/branches/${id}/analytics/dynamics`,
      params,
    )
  }

  async getRatingsInPlatforms(
    id: string,
    params: object,
  ): Promise<any> {
    return await this.get<any>(
      `/branches/${id}/analytics/rating-in-platforms`,
      params,
    )
  }

  async getBranches(params?: object): Promise<any> {
    return await this.get<any>('/branches', params)
  }

  async getEventTypes(params?: object): Promise<any> {
    return await this.get<any>('/event-types', params)
  }

  async getBranchRequests(
    id: string | RouteParamValue[],
    params?: object,
  ): Promise<any> {
    return await this.get<any>(`/branches/${id}/requests`, params)
  }

  async getBranchStaff(
    id: string | RouteParamValue[],
    params?: object,
  ): Promise<any> {
    return await this.get<any>(`/branches/${id}/staffs`, params)
  }

  async getBranchReviews(
    id: string | RouteParamValue[],
    params?: object,
  ): Promise<ReviewsResponse> {
    return await this.get<any>(`/branches/${id}/reviews`, params)
  }

  async deleteBranchEmployee(
    branchId: string | RouteParamValue[],
    employeeId: number,
  ): Promise<any> {
    return await this.delete<any>(
      `/branches/${branchId}/staffs/${employeeId}`,
    )
  }

  async updateActivePlatform(
    id: string | RouteParamValue[],
    data: object,
  ): Promise<any> {
    return await this.call<any>(
      'PUT',
        `branches/${id}/analytics/update-active-platform`,
        data,
    )
  }

  async updateRequests(id: string, value: number): Promise<any> {
    return await this.call<any>(
      'POST',
        `/branches/${id}/request-for-doctor`,
        {
          requests_for_doctor: value,
        },
    )
  }

  async requestsStats(hash: string): Promise<any> {
    return await this.get('requests-stats', {
      hash,
    })
  }
}

export default BranchModule
