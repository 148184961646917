import { useAuthUser } from '~/composables/auth/useAuthUser'
import { useAuthCookie } from '~/composables/auth/useAuthCookie'

export default defineNuxtPlugin(async () => {
  const { me } = useAuth()
  const authUser = useAuthUser()
  const token = useAuthCookie()
  if (authUser.value && token.value)
    return
  try {
    await me()
  }
  catch (e) {
    authUser.value = null
    token.value = null
  }
})
