import Toast, { useToast } from 'vue-toastification'

import { defineNuxtPlugin } from '#app'
import 'vue-toastification/dist/index.css'
import '@/assets/css/toast-override.scss'

export default defineNuxtPlugin(({ vueApp }) => {
  const options = {
    transition: 'fade',
    maxToasts: 5,
    closeButton: false,
    hideProgressBar: true,
    timeout: 3000,
    position: 'top-center',
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: true,
    icon: true,
  }

  vueApp.use(Toast, options)
  return {
    provide: {
      toast: useToast(),
    },
  }
})
