import { defineStore } from 'pinia'
import type { Branch } from '~/types/branch'
import type { Provide } from '~/plugins/api'

export const useBranchStore = defineStore('branches', () => {
  const branches = ref<Branch[]>([])
  const filters = ref({})
  const branch = ref<Branch>({} as Branch)
  const {
    $api: { branchService },
  }: Provide = useNuxtApp()

  const fetchBranches = async (params?: object) => {
    await branchService.getBranches(params).then((response: any) => {
      branches.value = response.data
    })
  }
  const fetchBranch = async (routeId: string | number) => {
    await branchService.getBranch(routeId).then((response: any) => {
      branch.value = response.data
    })
  }
  const resetState = (): void => {
    useBranchStore().$reset()
  }

  return {
    resetState,
    branch,
    filters,
    branches,
    fetchBranches,
    fetchBranch,
  }
})
