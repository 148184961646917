import type { ILoginInput, ILoginResponse } from 'types'
import HttpFactory from '../factory'

class UserModule extends HttpFactory {
  async login(credentials: ILoginInput): Promise<ILoginResponse> {
    return await this.call<ILoginResponse>('POST', '/login', credentials)
  }

  async updateUser(credentials: ILoginInput): Promise<ILoginResponse> {
    return await this.call<ILoginResponse>('PUT', '/user', credentials)
  }

  async changeRequestStatus(branchId: any): Promise<ILoginResponse> {
    return await this.post<ILoginResponse>(
      `/branches/${branchId}/toggle-requests-status`,
    )
  }

  async logout(): Promise<ILoginResponse> {
    return await this.post<ILoginResponse>('/logout')
  }

  async generateApi(): Promise<ILoginResponse> {
    return await this.post<ILoginResponse>('/user/generate-api-key')
  }

  async me(): Promise<ILoginResponse> {
    return await this.get<ILoginResponse>('/user')
  }

  async updateUserData(): Promise<ILoginResponse> {
    return await this.put<ILoginResponse>('/branches/all', {})
  }
}

export default UserModule
