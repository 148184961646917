import { useAuthUser } from '~/composables/auth/useAuthUser'
import { useAuthCookie } from '~/composables/auth/useAuthCookie'
import type { Provide } from '~/plugins/api'

export function useAuth() {
  const { $api }: Provide = useNuxtApp()
  const authUser = useAuthUser()
  const authCookie = useAuthCookie()
  const setUser = (user: any) => {
    authUser.value = user
  }
  const setCookie = (cookie: string) => {
    authCookie.value = cookie
  }
  const logout = async () => {
    authUser.value = null
    authCookie.value = null
    navigateTo('/login')
    await $api.auth.logout()
  }
  const me = async () => {
    if (!authUser.value && authCookie.value) {
      try {
        const user = await $api.auth.me()
        await setUser(user)
      }
      catch (e) {
        navigateTo('/login')
        authUser.value = null
        authCookie.value = null
      }
      return authUser
    }
  }
  const login = async (credentials: any) => {
    const user = await $api.auth.login(credentials)
    await setCookie(user.token)
    await me()
    navigateTo('/branches')
  }

  return {
    setUser,
    setCookie,
    login,
    logout,
    me,
  }
}
