import revive_payload_client_4sVQNw7RlN from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_tbFNToZNim from "/app/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import plugin_9MY4eAfYMX from "/app/node_modules/@artmizu/yandex-metrika-nuxt/dist/runtime/plugin.mjs";
import plugin_UC48QI1yOB from "/app/node_modules/@hypernym/nuxt-anime/dist/runtime/plugin.mjs";
import switch_locale_path_ssr_5csfIgkrBP from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/switch-locale-path-ssr.js";
import i18n_sq1MuCrqbC from "/app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.js";
import api_GFfDXud5Cr from "/app/plugins/api.ts";
import filters_VNgPjx6zHj from "/app/plugins/filters.ts";
import me_YJ4xT53NdQ from "/app/plugins/me.ts";
import sentry_client_shVUlIjFLk from "/app/plugins/sentry.client.ts";
import vue_awesome_paginate_l1b0wTlGPB from "/app/plugins/vue-awesome-paginate.js";
import vue_draggable_UPVdqOrVyH from "/app/plugins/vue-draggable.js";
import vue_toastification_client_F29MfCWaUJ from "/app/plugins/vue-toastification.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_tbFNToZNim,
  plugin_9MY4eAfYMX,
  plugin_UC48QI1yOB,
  switch_locale_path_ssr_5csfIgkrBP,
  i18n_sq1MuCrqbC,
  api_GFfDXud5Cr,
  filters_VNgPjx6zHj,
  me_YJ4xT53NdQ,
  sentry_client_shVUlIjFLk,
  vue_awesome_paginate_l1b0wTlGPB,
  vue_draggable_UPVdqOrVyH,
  vue_toastification_client_F29MfCWaUJ
]