<script setup lang="ts">
const props = defineProps({
  nuxtIcon: { type: String, default: '' },
  icon: { type: String, default: '' },
  filled: { type: Boolean, default: false },
  to: { type: String, default: '' },
  target: { type: String, default: '_blank' },
})
</script>

<template>
  <NuxtLink
    no-prefetch
    :target="target"
    :to="to"
    class="cursor-pointer w-fit flex flex-nowrap gap-2 items-center text-blue-500"
  >
    <nuxt-icon v-if="nuxtIcon" :filled="filled" :name="props.nuxtIcon" />
    <Icon v-else-if="icon" :name="icon" />
    <div class="border-b border-blue-500 hover:border-blue-300">
      <slot />
    </div>
  </NuxtLink>
</template>
