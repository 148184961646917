import type { $Fetch } from 'ofetch'

class HttpFactory {
  private $fetch: $Fetch

  constructor(fetcher: $Fetch) {
    this.$fetch = fetcher
  }

  async call<T>(
    method: string,
    url: string,
    data?: object | null,
    params?: object,
    extras = {},
  ): Promise<T> {
    return await this.$fetch(url, {
      method,
      body: data,
      params: {
        ...params,
      },
      ...extras,
    })
  }

  async get<T>(url: string, params: object = {}, extras = {}): Promise<T> {
    return await this.$fetch(url, {
      method: 'GET',
      params: {
        ...params,
      },
      ...extras,
    })
  }

  async post<T>(url: string, extras = {}): Promise<T> {
    return await this.$fetch(url, {
      method: 'POST',
      ...extras,
    })
  }

  async delete<T>(url: string, extras = {}): Promise<T> {
    return await this.$fetch(url, {
      method: 'DELETE',
      ...extras,
    })
  }

  async put<T>(url: string, extras = {}): Promise<T> {
    return await this.$fetch(url, {
      method: 'PUT',
      ...extras,
    })
  }
}

export default HttpFactory
