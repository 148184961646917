<script setup lang="ts">
defineProps({
  width: {
    type: String,
    required: false,
    default: '370px',
  },
})
const showPopup = ref(false)
</script>

<template>
  <div class="relative">
    <Icon
      class="text-secondary-500 cursor-pointer"
      name="material-symbols:info-outline"
      @mouseover="showPopup = true"
      @mouseleave="showPopup = false"
    />
    <transition>
      <div
        v-if="showPopup"
        :style="`width: ${width}`"
        :class="{
          'min-w-[370px]': !width,
          'max-w-[370px]': !width,
        }"
        class="absolute inline-block z-50 w-[370px] whitespace-pre-line -translate-x-1/2 top-10 pl-4 pt-2 pr-5 pb-3 bg-black rounded-lg text-white text-primary-small"
      >
        <slot />
      </div>
    </transition>
  </div>
</template>

<style scoped>
.v-enter-active,
.v-leave-active {
  transition: opacity 0.3s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
</style>
